import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { ProductsContext } from "../ProductsProvider"
import { CartContext } from "../Cart/CartProvider"
import "react-dropdown/style.css"
import CaretDropDown from "./CaretDropdown"
import AddFav from "../Wishlist/AddFav"
import AddProductModal from "./AddProductModal"

const Product = ({ product }) => {
  const { cartAdd, showMenu, setShowMenu } = useContext(CartContext)
  const { products } = useContext(ProductsContext)

  const toggleMenu = () => setShowMenu(!showMenu)

  // Switch from using the barebones Product object used to
  // create the static site, to the enhanced object available
  // through Context
  product = products.find((p) => p.id === product.id)
  //console.log("rendering enhanced product", product)

  /* Product Images */
  const [imageIndex, setImageIndex] = useState(0)
  const SetImage = (index) => {
    setImageIndex(index)
  }

  const isBespoke = product.metadata.type === "bespoke"
  const isRing = product.metadata.type === "rings"

  const NextImage = () => {
    if (imageIndex < product.images.length - 1) {
      setImageIndex(imageIndex + 1)
    }
    if (imageIndex === product.images.length - 1) {
      setImageIndex(0)
    }
  }

  /* Menu */
  // TODO
  //   const { toggleCart } = useContext(CartContext)
  //   const toggleMenu = toggleCart

  /* Thumbs Fragment */

  const thumbs = product.images.map((img, currentIndex) => {
    return (
      <div
        key={currentIndex}
        className="productimagethumbs"
        onClick={() => SetImage(currentIndex)}
      >
        <div>{<Img fluid={product.images[currentIndex]} />}</div>
      </div>
    )
  })

  return (
    <div className="productpagecontainer">
      <div className="imagewrap">
        <div className="imagesection">
          <div className="navlinks">
            <Link to="/">home</Link> <div className="navspacer">/ </div>
            <Link to="/collection">collection</Link>{" "}
            <div className="navspacer">/ </div>
            <Link to={`/collection/${product.metadata.type}`}>
              {product.metadata.type}
            </Link>
          </div>
          <div style={{ margin: "" }} onClick={NextImage}>
            {<Img fluid={product.images[imageIndex]} alt={product.name} />}
          </div>
          <div className="productthumbcontainer">{thumbs}</div>
        </div>
      </div>
      <div className="productcontentwrap">
        <div className="productpagename">{product.name}</div>
        <div className="productpageprice">
          ${product.prices[0].unit_amount / 100}.00 AUD
        </div>
        <AddFav productId={product.id} />
        <br />
        <br />
        <CaretDropDown text="Description" isOpen>
          <div
            className="productpagedescription"
            style={{ textAlign: "justify" }}
          >
            {product.description}
            {isBespoke ? (
              <div className="productpagedescriptionbespoke">
                {" "}
                <br />
                Please note, this design is an example of our bespoke work.
                Contact us to begin your personalised bespoke journey.
              </div>
            ) : (
              ""
            )}
          </div>
        </CaretDropDown>
        <CaretDropDown text="Materials" isOpen={false}>
          <div
            className="productpagedescription"
            style={{ textAlign: "justify" }}
          >
            <div dangerouslySetInnerHTML={{ __html: product.materials }} />
          </div>
        </CaretDropDown>
        <CaretDropDown text="Shipping Info" isOpen={false}>
          <div
            className="productpagedescription"
            style={{ textAlign: "justify" }}
          >
            All Dianne Rowe Private Collection pieces are hand made to order.
            This means that each piece is unique and made especially for you. If
            you need a piece before a specific date, please contact us before
            ordering to ensure this will be possible and avoid any
            disappointment. Every effort will be made to have your piece to you
            as quickly as possible.
            <br />
            <br />
            Australian domestic orders are sent express via Australia Post. You
            will be contacted on dispatch of your order with a tracking number
            which you can check at www.auspost.com.au. Dianne Rowe Private
            Collection ships worldwide. International standard shipping is sent
            via Australia Post. These packages are registered and trackable, you
            will be contacted on dispatch of your order with a tracking number
            which you can check at www.auspost.com.au.
            <br />
            <br />
            International standard parcels can take anywhere between 3 and 10
            working days to arrive. International express shipping is via
            Australia Post. You will be contacted on dispatch of your order with
            a tracking number and details of how to track.
          </div>
        </CaretDropDown>
        <div className="disclaimer" style={{ fontWeight: "bold" }}>
          As all products are bespoke and crafted to order, please allow 4 to 6
          weeks for creation and delivery.
        </div>
        {isBespoke ? (
          ""
        ) : (
          <div className="addcartwrap">
            {isRing ? (
              <AddProductModal product={product}>
                <button className="favcombined wishlistadd">Add to cart</button>
              </AddProductModal>
            ) : (
              <button
                className="favcombined wishlistadd"
                onClick={() => {
                  cartAdd(product)
                  toggleMenu(!showMenu)
                }}
              >
                Add to cart
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Product
