import React from "react"
import Layout from "../components/layout"
import Product from "../components/Product/Product"
import SEO from "../components/seo"

const ProductTemplate = ({ pageContext: { product }, data }) => {
  return (
    <Layout>
      {/* <SEO title="Name" keywords={[Keywords]} /> */}
      <div className="productpagewrap">
        <Product product={product.node} />
      </div>
    </Layout>
  )
}

export default ProductTemplate
