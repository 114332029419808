import React, { useContext, useState } from "react"
import Popup from "reactjs-popup"
import Img from "gatsby-image"
import { CartContext } from "../Cart/CartProvider"
import RingSize from "./RingSize"
import RingSizeChart from "../../pages/Rowe Collection International Ring Size Chart.pdf"

const AddProductModal = ({ product, children }) => {
  const { cartAdd, showMenu, setShowMenu } = useContext(CartContext)
  const productImage = product.images?.[0] || ""
  const [selectedValue, setSelectedValue] = useState("")
  const [open, setOpen] = useState(false)
  const close = () => setOpen(false)
  const handleSelectSize = (value) => {
    //console.log("handleSelectSize", value)
    setSelectedValue(value)
  }

  const toggleMenu = () => setShowMenu(!showMenu)

  const handleAddtoCart = () => {
    //console.log("handleAddtoCart", selectedValue)
    const price = product.prices
      .filter((price) => price.metadata["ringSize"] === selectedValue)
      .pop()
    //console.log("Adding Price", price)
    cartAdd(product, price)
    toggleMenu()
  }

  return (
    <>
      <div className="popouterwrap">
        <Popup open={open} closeOnDocumentClick onClose={close}>
          <div className="popinnerwrap" style={{ backgroundColor: "white" }}>
            <div className="popmodalimgwrap">
              <div className="popmodalimg">
                <Img fluid={productImage} alt={product.name} />
              </div>
            </div>
            <div className="subclose">
              <button className="closebutton" onClick={close}>
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="popmodalcartwrap">
              <div className="popmodalringsize">
                {/* {!hasSelectedRingSize && hasAttempedCartAdd ? (
                <div className="alert alert-warning" role="alert">
                  Please select a ring size to continue.
                </div>
              ) : (
                ""
              )} */}
                <RingSize
                  product={product}
                  selectedSize={selectedValue}
                  handleSelectSize={handleSelectSize}
                />
              </div>
              <div className="popaddcart">
                {selectedValue !== "" ? (
                  <button
                    className="popmodalbutton"
                    onClick={handleAddtoCart}
                    style={{ cursor: "pointer", display: "block" }}
                  >
                    Add to cart {/* THIS IS IN THE MODAL */}
                  </button>
                ) : (
                  <button className="popmodalbutton">
                    Please Select Ring Size
                  </button>
                )}
              </div>
              <div className="pdfstyles2">
                <button type="submit" className="popmodalbutton">
                  <a
                    href={RingSizeChart}
                    target="_blank"
                    rel="noreferrer"
                    className="popmodalbutton"
                    style={{ cursor: "pointer" }}
                    color="black"
                  >
                    Ring Size Chart
                  </a>
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </div>
      <div className="stylecartbutton">
        <button
          className="favcombined wishlistadd"
          onClick={() => {
            setOpen(true)
          }}
        >
          Add to cart
        </button>
      </div>
    </>
  )
}

export default AddProductModal
