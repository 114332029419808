import React, { useContext } from "react"
import PropTypes from "prop-types"
import { FavContext } from "../FavProvider"
import { ProductsContext } from "../ProductsProvider"

const AddFav = ({ productId }) => {
  const { addfav, remove, hasfav } = useContext(FavContext)
  const { products } = useContext(ProductsContext)
  const product = products.filter((product) => product.id === productId).pop()
  const isFaved = hasfav(product.id)
  const styleForHeart = isFaved ? "fa fa-heart" : "fa fa-heart-o"
  return (
    <div className="favaddwrap">
      <div className="favaddbuttonwidth">
        <button
          className="favcombinednew"
          onClick={() => {
            if (isFaved) {
              remove(product.id)
            } else {
              addfav(product.id)
            }
          }}
        >
          <div className="wishlistadd">
            {isFaved ? "Remove from wishlist" : "Add to wishlist"}
          </div>
          <div className="productfav">
            <i className={styleForHeart} aria-hidden="true"></i>
          </div>
        </button>
      </div>
    </div>
  )
}

AddFav.propTypes = {
  productId: PropTypes.string.isRequired,
}

export default AddFav
