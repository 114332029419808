import React, { Component, useState } from "react"
import Dropdown from "react-dropdown"

const arrowClosed = <span className="arrow-closed" />
const arrowOpen = <span className="arrow-open" />

const RingSize = ({ product, handleSelectSize, selectedSize }) => {
  const [selectedValue, setSelectedValue] = useState(selectedSize)
  const options = product.prices
    .map((prices) => prices.metadata["ringSize"])
    .filter((v) => v !== null)
    .sort((a, b) => {
      return Number.parseFloat(a) - Number.parseFloat(b)
    })

  const handleChange = (event) => {
    const value = event.target.value
    //console.log("handleChange in RingSize", selectedValue, " => ", value)
    setSelectedValue(value)
    handleSelectSize(value)
  }

  return (
    <div>
      <div className="droptitle">Ring Size:</div>
      <br />
      <div className="dropring">
        <select onChange={handleChange}>
          {options.map((value) =>
            selectedValue === value ? (
              <option selected key={value}>
                {value}
              </option>
            ) : (
              <option key={value}>{value}</option>
            )
          )}
        </select>
      </div>
    </div>
  )
}

export default RingSize
