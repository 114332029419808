import React, { useState } from "react"
import { useSpring, animated } from "react-spring"
import PropTypes from "prop-types"
import Accordian from "../Accordian"

const CaretDropdown = ({ text, children, isOpen }) => {
  const [pressed, setPressed] = useState(
    typeof isOpen === "undefined" || isOpen == false ? false : true
  )
  const props = useSpring({
    transform: `rotateZ(${pressed ? "90deg" : "0deg"})`,
  })

  return (
    <div>
      <div className="dropheader" onClick={() => setPressed(!pressed)}>
        <animated.button className="caretdropdown" style={props}>
          <i className="fa fa-caret-right" aria-hidden="true"></i>
        </animated.button>
        <div className="carettext">{text}</div>
      </div>
      <Accordian isOpen={pressed}>{children}</Accordian>
    </div>
  )
}

CaretDropdown.propTypes = {
  children: PropTypes.any.isRequired,
}

export default CaretDropdown
